export const FETCH = 'fetchArchivo'
export const CREATE = 'createArchivo'
export const UPDATE = 'updateArchivo'
export const LOADING = 'loadingArchivo'
export const SET_ID = 'setArchivoId'
export const SET_ID_CLIENTE = 'setArchivoIdCliente'

export const url = {
  root: '/archivo',
  cliente: '/archivo/cliente'
}

export const form = {
  concepto: 'Concepto',
  cliente: 'Cliente',
  actividad: 'Actividad',
  pendiente: 'Pendiente',
  usuario: 'Usuario',
  cobro: 'Cobro',
  categoria: 'Categoria',
  analytics: 'Analytics'
}

export const permission = {
  registrar: 'Puede Registrar',
  modificar: 'Puede Modificar',
  eliminar: 'Puede Eliminar',
  listar: 'Puede Lista',
  cambiarestado: 'Puede Cambiar Estado',
  listarpresupuesto: 'Puede Listar Presupuesto',
  listarservicio: 'Puede Listar Servicios',
  listarcompra: 'Puede Listar Compras',
  listarrelevamiento: 'Puede Listar Relevamientos'

}

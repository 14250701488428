<template>
  <c-progress-circular
    indeterminate
    color="primary"
    :size="50"
    :width="10"
  ></c-progress-circular>
</template>
<script>
export default {}
</script>

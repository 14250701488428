export const FETCH = 'fetchAnalytics'
export const FETCH_PENDIENTE = 'fetchPendientes'
export const FETCH_CLIENTE = 'fetchCliente'
export const FETCH_CONCEPTO = 'fetchConcepto'
export const FETCH_TECNICO = 'fetchTecnico'
export const FETCH_CATEGORIA = 'fetchCategoria'
export const FETCH_ESTADO = 'fetchEstados'
export const FETCH_COBRO_TECNICO = 'fetchCobroTecnico'
export const CHANGE_PARAMS = 'ChangeParams'
export const LOADING = 'loadingAnalytics'

export const url = {
  actividad: '/analytics/actividad',
  pendiente: '/analytics/pendiente',
  cliente: '/analytics/cliente',
  concepto: '/analytics/concepto',
  tecnico: '/analytics/tecnico',
  categoria: '/analytics/categoria',
  estado: '/analytics/estado',
  cobrotecnico: '/analytics/cobrotecnico'
}

<template>
  <v-app>
    <v-main class="primary">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>
